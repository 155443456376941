<template>
    <div>

<!--        <div class="mt-6 bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">-->
<!--            <div class="md:grid md:grid-cols-3 md:gap-6">-->
<!--                <div class="md:col-span-1">-->
<!--                    <h3 class="text-lg font-medium leading-6 text-gray-900">Personal Information</h3>-->
<!--                    <p class="mt-1 text-sm leading-5 text-gray-500">-->
<!--                        Use a permanent address where you can receive mail.-->
<!--                    </p>-->
<!--                </div>-->
<!--                <div class="mt-5 md:mt-0 md:col-span-2">-->
<!--                    <form action="#" method="POST">-->
<!--                        <div class="grid grid-cols-6 gap-6">-->
<!--                            <div class="col-span-6 sm:col-span-3">-->
<!--                                <label for="first_name" class="block text-sm font-medium leading-5 text-gray-700">First name</label>-->
<!--                                <p v-if="!isEditingPeronalInformation" class="mt-1 text-xl text-jiruto-zotDarker font-medium block w-full py-2 px-3">{{ firstName }}</p>-->
<!--                                <input v-else id="first_name" class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">-->
<!--                            </div>-->

<!--                            <div class="col-span-6 sm:col-span-3">-->
<!--                                <label for="last_name" class="block text-sm font-medium leading-5 text-gray-700">Last name</label>-->
<!--                                <p v-if="!isEditingPeronalInformation" class="mt-1 text-xl text-jiruto-zotDarker font-medium block w-full py-2 px-3">{{ lastName }}</p>-->
<!--                                <input v-else  id="last_name" class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">-->
<!--                            </div>-->

<!--                            <div class="col-span-6 sm:col-span-4">-->
<!--                                <label for="email_address" class="block text-sm font-medium leading-5 text-gray-700">Username</label>-->
<!--                                <p v-if="!isEditingPeronalInformation" class="mt-1 text-xl text-jiruto-zotDarker font-medium block w-full py-2 px-3">{{ username }}</p>-->
<!--                                <input v-else id="email_address" class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">-->
<!--                            </div>-->
<!--                        </div>-->

<!--                        <div class="mt-8 border-t border-gray-200 pt-5">-->
<!--                            <div class="flex justify-end">-->
<!--                              <span class="inline-flex rounded-md shadow-sm">-->
<!--                                <button type="button" @click="clearPasswordFields" class="py-2 px-4 border border-gray-300 rounded-md text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out">-->
<!--                                  Cancel-->
<!--                                </button>-->
<!--                              </span>-->
<!--                                <span class="ml-3 inline-flex rounded-md shadow-sm">-->
<!--                                    <button type="submit" class="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">-->
<!--                                      Save Changes-->
<!--                                    </button>-->
<!--                                </span>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </form>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->




<!--        <div class="bg-white shadow-xl-white px-4 py-5 sm:rounded-lg sm:p-6 mt-10">-->
<!--            <div class="md:grid md:grid-cols-3 md:gap-6">-->
<!--                <div class="md:col-span-1">-->
<!--                    <h3 class="text-lg font-medium leading-6 text-gray-900">Change Username</h3>-->
<!--                    <p class="mt-1 text-sm leading-5 text-gray-500 font-bold">-->
<!--                        Username guidelines:-->
<!--                        <span class="mt-1 font-normal text-sm leading-5 text-gray-500">-->
<!--                        must start and end with a letter, can only use "-" and "_" as special characters, not adjacent.-->
<!--                    </span>-->
<!--                    </p>-->

<!--                </div>-->
<!--                <div class="mt-5 md:mt-0 md:col-span-2">-->
<!--                    <form @submit.prevent="changePassword">-->
<!--                        <div class="grid grid-cols-6 gap-6">-->

<!--                            <div class="col-span-6 sm:col-span-4">-->
<!--                                <label for="newPassword" class="block text-sm font-medium leading-5 text-gray-700">Current username</label>-->

<!--&lt;!&ndash;                                <input type="password" v-model="newPassword" id="newPassword"&ndash;&gt;-->
<!--&lt;!&ndash;                                       @focus="focus='newPassword'" @blur="newPasswordBlur"&ndash;&gt;-->
<!--&lt;!&ndash;                                       class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" :class="{'border-jiruto-flatRedDarker': $v.newPassword.$error && focus !== 'newPassword'}" />&ndash;&gt;-->
<!--                                <p class="mt-1 block w-full text-4xl py-1 px-3  font-bold rounded-md  text-cool-gray-500 text-center tracking-wider underline">{{$store.getters.getUsername}}</p>-->
<!--                                <div v-if="$v.newPassword.$error && focus !== 'newPassword'">-->
<!--                                    <p v-if="!$v.newPassword.required" class="text-red-800 text-sm">You must enter a password</p>-->
<!--                                    <p v-if="!$v.newPassword.strongPassword && $v.newPassword.required" class="text-red-800 text-sm">Password should have 1 lowercase, 1 uppercase, 1 digit, 1 special character</p>-->
<!--                                    <p v-if="!$v.newPassword.minLength && $v.newPassword.required" class="text-red-800 text-sm">Password should be at least 8 characters long</p>-->
<!--                                </div>-->
<!--                            </div>-->

<!--                            <div class="col-span-6 sm:col-span-4">-->
<!--                                <label for="newUsername" class="block text-sm font-medium leading-5 text-gray-700">New username</label>-->
<!--                                <input type="password" v-model="currentPassword" id="newUsername"-->
<!--                                       @focus="currentPasswordFocus" @blur="currentPasswordBlur"-->
<!--                                       class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" :class="{'border-jiruto-flatRedDarker': (incorrectPassword || $v.currentPassword.$error) && focus !== 'currentPassword'}" />-->
<!--                                <div v-if="$v.currentPassword.$error && focus !== 'newUsername'">-->
<!--                                    <p v-if="!$v.currentPassword.required" class="text-red-800 text-sm">You must enter your current password</p>-->
<!--                                </div>-->
<!--                                <p v-if="incorrectPassword" class="text-red-800 text-sm">Incorrect Password</p>-->
<!--                            </div>-->

<!--                            <div class="col-span-6 sm:col-span-4">-->
<!--                                <label for="repeatUsername" class="block text-sm font-medium leading-5 text-gray-700">Repeat username</label>-->
<!--                                <input type="password" v-model="repeatNewPassword" id="repeatUsername"-->
<!--                                       @focus="focus='repeatNewPassword'" @blur="repeatNewPasswordBlur"-->
<!--                                       class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"  :class="{'border-jiruto-flatRedDarker': $v.repeatNewPassword.$error && focus !== 'repeatNewPassword'}" />-->
<!--                                <div v-if="$v.repeatNewPassword.$error && focus !== 'repeatNewPassword'">-->
<!--                                    <p v-if="!$v.repeatNewPassword.required" class="text-red-800 text-sm">You must re-enter the password</p>-->
<!--                                    &lt;!&ndash;                                    <p v-if="!$v.repeatNewPassword.strongPassword && $v.repeatNewPassword.required" class="text-red-800 text-sm">Password should have 1 lowercase, 1 uppercase, 1 digit, 1 special character and be atleast 8 characters long</p>&ndash;&gt;-->
<!--                                    <p v-if="!$v.repeatNewPassword.sameAsPassword && $v.repeatNewPassword.required" class="text-red-800 text-sm">Passwords do not match</p>-->
<!--                                    &lt;!&ndash;                                    <p v-if="!$v.repeatNewPassword.minLength && $v.repeatNewPassword.required" class="text-red-800 text-sm">Password should be at least 8 characters long</p>&ndash;&gt;-->
<!--                                </div>-->
<!--                            </div>-->


<!--                        </div>-->


<!--                        <div class="mt-8 border-t border-gray-200 pt-5">-->
<!--                            <div class="flex justify-end">-->
<!--                              <span class="inline-flex rounded-md shadow-sm">-->
<!--                                <button type="button" @click="clearPasswordFields" class="py-2 px-4 border border-gray-300 rounded-md text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out">-->
<!--                                  Cancel-->
<!--                                </button>-->
<!--                              </span>-->
<!--                                <span class="ml-3 inline-flex rounded-md shadow-sm">-->
<!--                                    <button type="submit" class="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">-->
<!--                                      Change Username-->
<!--                                    </button>-->
<!--                                </span>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </form>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->




        <div class="bg-white shadow-xl-white px-4 py-5 sm:rounded-lg sm:p-6">
            <div class="md:grid md:grid-cols-3 md:gap-6">
<!--                <div class="md:col-span-1">-->
<!--                    <h3 class="text-lg font-medium leading-6 text-gray-900">Update Password</h3>-->
<!--                    <p class="mt-1 text-sm leading-5 text-gray-500">-->
<!--                        Use this form to set a new password. Please choose a strong enough password and keep it somewhere safe.-->
<!--                    </p>-->
<!--                </div>-->
                <div class="mt-5 md:mt-0 md:col-span-3">
                    <h2 class="text-lg leading-6 font-medium text-gray-900">Change Password</h2>
                    <p class="mt-1 text-sm leading-5 text-gray-500">
                        Make sure you choose a strong password.
                    </p>
                </div>
                <div class="mt-5 md:mt-0 md:col-span-3">
                    <form @submit.prevent="changePassword">
                        <div class="grid grid-cols-6 gap-6">

                            <div class="col-span-6 sm:col-span-4">
                                <label for="currentPassword" class="block text-sm font-medium leading-5 text-gray-700">Current password</label>
                                <input type="password" v-model="currentPassword" id="currentPassword"
                                       @focus="currentPasswordFocus" @blur="currentPasswordBlur"
                                       class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" :class="{'border-jiruto-flatRedDarker': (incorrectPassword || $v.currentPassword.$error) && focus !== 'currentPassword'}" />
                                <div v-if="$v.currentPassword.$error && focus !== 'currentPassword'">
                                    <p v-if="!$v.currentPassword.required" class="text-red-800 text-sm">You must enter your current password</p>
                                </div>
                                <p v-if="incorrectPassword" class="text-red-800 text-sm">Incorrect Password</p>
                            </div>

                            <div class="col-span-6 sm:col-span-4">
                                <label for="newPassword" class="block text-sm font-medium leading-5 text-gray-700">New password</label>
                                <input type="password" v-model="newPassword" id="newPassword"
                                       @focus="focus='newPassword'" @blur="newPasswordBlur"
                                       class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" :class="{'border-jiruto-flatRedDarker': $v.newPassword.$error && focus !== 'newPassword'}" />
                                <div v-if="$v.newPassword.$error && focus !== 'newPassword'">
                                    <p v-if="!$v.newPassword.required" class="text-red-800 text-sm">You must enter a password</p>
                                    <p v-if="!$v.newPassword.strongPassword && $v.newPassword.required" class="text-red-800 text-sm">Password should have 1 lowercase, 1 uppercase, 1 digit, 1 special character</p>
                                    <p v-if="!$v.newPassword.minLength && $v.newPassword.required" class="text-red-800 text-sm">Password should be at least 8 characters long</p>
                                </div>
                            </div>


                            <div class="col-span-6 sm:col-span-4">
                                <label for="repeatNewPassword" class="block text-sm font-medium leading-5 text-gray-700">Repeat new password</label>
                                <input type="password" v-model="repeatNewPassword" id="repeatNewPassword"
                                       @focus="focus='repeatNewPassword'" @blur="repeatNewPasswordBlur"
                                       class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"  :class="{'border-jiruto-flatRedDarker': $v.repeatNewPassword.$error && focus !== 'repeatNewPassword'}" />
                                <div v-if="$v.repeatNewPassword.$error && focus !== 'repeatNewPassword'">
                                    <p v-if="!$v.repeatNewPassword.required" class="text-red-800 text-sm">You must re-enter the password</p>
<!--                                    <p v-if="!$v.repeatNewPassword.strongPassword && $v.repeatNewPassword.required" class="text-red-800 text-sm">Password should have 1 lowercase, 1 uppercase, 1 digit, 1 special character and be atleast 8 characters long</p>-->
                                    <p v-if="!$v.repeatNewPassword.sameAsPassword && $v.repeatNewPassword.required" class="text-red-800 text-sm">Passwords do not match</p>
<!--                                    <p v-if="!$v.repeatNewPassword.minLength && $v.repeatNewPassword.required" class="text-red-800 text-sm">Password should be at least 8 characters long</p>-->
                                </div>
                            </div>

                            <div class="col-span-6 sm:col-span-4">
                                <p class="block text-xs font-medium leading-5 text-gray-500">* Passwords must be longer than 8 characters and contain 1 lowercase letter, 1 uppercase letter, and 1 special character</p>
                            </div>

                            <div class="col-span-6 sm:col-span-4">
                                <button type="button" @click="resetPassword" class="block text-sm font-medium leading-5 text-blue-500">I forgot my current password</button>
                            </div>


                        </div>


                        <div class="mt-8 border-t border-gray-200 pt-5">
                            <div class="flex justify-end">
                                <span class="inline-flex rounded-md shadow-sm">
                                    <button type="button" @click="clearPasswordFields" class="py-2 px-4 border border-gray-300 rounded-md text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out">
                                      Cancel
                                    </button>
                                </span>
                                <span class="ml-3 inline-flex rounded-md shadow-sm">
                                    <button type="submit" class="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">
                                      Update Password
                                    </button>
                                </span>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <successNotification :open="showSuccesSave">
            <template v-slot:header>
                Updated!
            </template>
            <template v-slot:text>
                Password updated successfully.
            </template>
        </successNotification>

        <resetModal :open="resetOpen" v-on:close-modal="resetOpen=false">
            <template v-slot:header>
                Password Reset Email Sent!
            </template>
            <template v-slot:text>
                Please check your email. If in the next few minutes no email arrives, please check your spam folder.
            </template>
        </resetModal>

    </div>
</template>


<script>
    import Modal from  './SuccessModal'

    import SuccessNotification from '../components/ActionNotification'
    import { required, sameAs, minLength } from 'vuelidate/lib/validators'
    import * as fb from '../firebaseConfig'
    export default {
        name: "ChangePassword",
        components: {
            successNotification: SuccessNotification,
            resetModal: Modal
        },
        data(){
            return {
                currentPassword: '',
                newPassword: '',
                repeatNewPassword: '',
                focus: null,
                showSuccesSave: false,
                incorrectPassword: false,
                resetOpen: false,
                isEditingPeronalInformation: false,
                username: this.$store.getters.getUsername,
                firstName: this.$store.getters.getFirstName,
                lastName: this.$store.getters.getLastName,

            }
        },
        created(){
            // this.username = this.$store.getters.getUsername
            // this.firstNAme
        },
        validations: {
            currentPassword: {
                required
            },
            newPassword: {
                required,
                minLength: minLength(8),
                strongPassword(password1) {
                    return (
                        /[a-z]/.test(password1) && // checks for a-z
                        /[A-Z]/.test(password1) && // checks for a-z
                        /[0-9]/.test(password1) && // checks for 0-9
                        /\W|_/.test(password1)  // checks for special char
                        // password1.length >= 8
                    );
                }
            },
            repeatNewPassword: {
                required,
                sameAsPassword: sameAs('newPassword'),
                minLength: minLength(8),
                strongPassword(password1) {
                    return (
                        /[a-z]/.test(password1) && // checks for a-z
                        /[A-Z]/.test(password1) && // checks for a-z
                        /[0-9]/.test(password1) && // checks for 0-9
                        /\W|_/.test(password1)  // checks for special char
                        // password1.length >= 8
                    );
                }
                // minLength: minLength(4)
            },
        },
        methods: {
            clearPasswordFields(){
                this.$v.$reset()
                this.currentPassword = '';
                this.newPassword = '';
                this.repeatNewPassword = '';
            },
            changePassword(){
                this.$v.$touch()
                if(!this.$v.$invalid){
                    const credential = fb.firebase.auth.EmailAuthProvider.credential(fb.auth.currentUser.email, this.currentPassword)
                    let loader = this.$loading.show({
                        onCancel: this.onCancel,
                    });
                    fb.auth.currentUser.reauthenticateWithCredential(credential).then(() => {
                        console.log("Success re-authenticating the user the user")
                        fb.auth.currentUser.updatePassword(this.newPassword).then(() => {
                            loader.hide()
                            this.showSuccesSave = true
                            this.clearPasswordFields()
                            setTimeout(() => this.showSuccesSave = false, 2000)
                            console.log("Success updating the password")
                        }). catch(err => {
                            loader.hide()
                            console.log("Error updating password", err)
                        })
                    }).catch((err) => {
                        loader.hide()
                        this.incorrectPassword = true
                        console.error("Error while re-authenticating user ", err)
                    })

                }
            },
            newPasswordBlur(){
                this.focus = null
                this.$v.newPassword.$touch()
            },
            repeatNewPasswordBlur(){
                this.focus = null
                this.$v.repeatNewPassword.$touch()
            },
            currentPasswordBlur(){
                this.focus = null
                this.$v.currentPassword.$touch()
                // this.incorrectPassword = false
            },
            currentPasswordFocus(){
                this.focus = 'currentPassword'
                this.incorrectPassword = false
            },
            resetPassword(){

                this.$v.$reset()
                let loader = this.$loading.show({
                    onCancel: this.onCancel,
                });
                fb.auth.sendPasswordResetEmail(fb.auth.currentUser.email)
                    .then(() => {
                        loader.hide()
                        this.resetOpen = true
                    })
                    .catch(err => {
                        loader.hide()
                        console.log("error reseting the password", err)
                    })
            }
        }
    }
</script>

<style scoped>

</style>
